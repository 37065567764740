import Link from 'next/link'

import { IChildren } from '@interfaces/global.type'

import styles from './Button.module.sass'

interface IProps {
  children: IChildren
  type?: 'button' | 'submit'
  onClick?: (e: any) => void
  href?: string
  className?: string
  disabled?: boolean
  style?: any
}

const Button = ({
  children,
  type = 'submit',
  onClick,
  href,
  className = 'button',
  disabled = false,
  style,
}: IProps) => {
  const defaultClassName = {
    button: disabled
      ? `${styles.newDisabled} ${className}`
      : `${styles.newButton} ${className}`,
  }

  return href ? (
    <Link href={href}>
      <button
        style={style}
        className={defaultClassName.button}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </Link>
  ) : (
    <button
      style={styles}
      className={defaultClassName.button}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
