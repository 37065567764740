import React, { useMemo, useState } from 'react'
import { ReactSVG } from 'react-svg'
import Link from 'next/link'
import { useRouter } from 'next/router'

import {
  IAccentColor,
  INavigationTypes,
  TAnimation,
} from '@interfaces/global.type'

import Button from '../Button/Button'
import NavLinksContainer from '../NavLinksContainer/NavLinksContainer'
import SideBar from '@components/UI/SideBar/SideBar'

import { ISidebarMock } from '@interfaces/global.type'

import styles from './Navigation.module.sass'

interface IProps {
  accentColor?: 'yellow' | 'blue'
  logoColor?: IAccentColor
  navLinksMock?: INavigationTypes
  sidebarMock?: ISidebarMock
}

const Navigation = ({
  accentColor = 'blue',
  navLinksMock,
  logoColor = 'blueYellow',
  sidebarMock = 'main',
}: IProps) => {
  const [isSidebarOpen, toggleSidebar] = useState(false)
  const [animation, setAnimation] = useState<TAnimation>('none')

  const router = useRouter()

  const handleToggleSidebar = () => {
    if (!isSidebarOpen) {
      setAnimation('shown')
    } else {
      setAnimation('hidden')
    }
    toggleSidebar((prev) => !prev)
  }

  const logoUrl = {
    yellow: '/icons/logo-yellow.svg',
    blue: '/icons/logo-blue.svg',
    blueYellow: '/icons/logo-blue-yellow-with-text.svg',
  }

  return (
    <nav className={styles.container}>
      <div className={styles.logoContainer}>
        <Link href={'/'}>
          <ReactSVG src={logoUrl[logoColor]} />
        </Link>
      </div>

      {navLinksMock && (
        <NavLinksContainer
          navLinksMock={navLinksMock}
          accentColor={accentColor}
        />
      )}

      <div className={styles.rightContainer}>
        {router.pathname !== '/login' && router.pathname !== '/cabinet' && (
          <div className={styles.btnLinkContainer}>
            <Button href='/cabinet' className='newButton'>
              Особистий кабінет
            </Button>
          </div>
        )}

        <div
          className={
            accentColor === 'blue'
              ? styles.iconBurderContainer
              : styles.iconBurderContainerYellow
          }
          onClick={handleToggleSidebar}
        >
          <ReactSVG src='/icons/burger.svg' />
        </div>
      </div>

      <SideBar
        animation={animation}
        handleToggleSidebar={handleToggleSidebar}
        sidebarMock={sidebarMock}
      />
    </nav>
  )
}

export default Navigation
