import Link from 'next/link'

import { IChildren } from '@interfaces/global.type'

import styles from './NavLinkItem.module.sass'

interface IProps {
  children: IChildren
  href: string
  accentColor?: 'yellow' | 'blue'
  isActive?: boolean
  [key: string]: any
}

const NavLinkItem = ({
  href,
  children,
  accentColor = 'blue',
  isActive = false,
}: IProps) => {
  const activeLinkStyles = {
    blue: isActive ? styles.containerActive : styles.container,
    yellow: isActive ? styles.containerActiveYellow : styles.containerYellow,
  }

  return (
    <div className={`${activeLinkStyles[accentColor]}`}>
      <Link href={href}>{children}</Link>
    </div>
  )
}

export default NavLinkItem
