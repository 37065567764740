import { ReactSVG } from 'react-svg'

import { IChildren } from '@interfaces/global.type'

import styles from './FooterLinkItem.module.sass'

interface IProps {
  children: IChildren
  iconSrc?: string
  iconGradientSrc?: string
  href?: string
  inlineStyles?: React.CSSProperties
  color?: 'yellow' | 'blue' | 'blueYellow'
  [key: string]: any
}

const stylesDefault = {
  blue: {
    container: styles.blueContainer,
    iconContainer: styles.blueIconContainer,
  },

  yellow: {
    container: '',
    iconContainer: '',
  },

  blueYellow: {
    container: '',
    iconContainer: '',
  },
}

const FooterLinkItem = ({
  children,
  iconSrc,
  iconGradientSrc,
  href,
  inlineStyles,
  color = 'yellow',
}: IProps) => {
  return (
    <div
      className={`${styles.container} ${stylesDefault[color].container}`}
      style={inlineStyles}>
      <a href={href} target="_blank" rel="noreferrer">
        <div
          className={`${styles.iconContainer} ${stylesDefault[color].iconContainer}`}>
          {iconGradientSrc ? (
            <ReactSVG src={`/icons/${iconGradientSrc}`} />
          ) : (
            <ReactSVG src={`/icons/${iconSrc}`} />
          )}
        </div>
        {children}
      </a>
    </div>
  )
}

export default FooterLinkItem
