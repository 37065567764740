export const contactsMock = [
  {
    id: 1,
    title: '+380 95 458 23 57',
    href: 'tel:+380954582357',
    iconSrc: 'ua-flag.svg',
  },
  {
    id: 2,
    title: '+421 951 212 417',
    href: 'tel:+421951212417',
    iconSrc: 'sk-flag.svg',
  },
  {
    id: 3,
    title: '+420 773 171 530',
    href: 'tel:+420773171530',
    iconSrc: 'cz-flag.svg',
  },
]

export const socialsDeliveryMock = [
  {
    id: 1,
    title: 'Perevozka.Delivery',
    href: 'https://www.facebook.com/Perevozka.Delivery',
    iconSrc: 'facebook.svg',
    iconGradientSrc: 'facebook-gradient.svg',
  },
  {
    id: 2,
    title: 'perevozka.delivery',
    href: 'https://www.instagram.com/perevozka.delivery/',
    iconSrc: 'instagram.svg',
    iconGradientSrc: 'instagram-gradient.svg',
  },
  {
    id: 3,
    title: 'perevozka_delivery',
    href: 'https://www.telegram.me/perevozka_delivery',
    iconSrc: 'telegram.svg',
    iconGradientSrc: 'telegram-gradient.svg',
  },
  {
    id: 4,
    title: 'perevozka.delivery@gmail.com',
    href: 'mailto:perevozka.delivery@gmail.com',
    iconSrc: 'gmail.svg',
    iconGradientSrc: 'gmail-gradient.svg',
  },
]

export const socialsTransportationMock = [
  {
    id: 1,
    title: 'Perevozka.Travel',
    href: 'https://www.facebook.com/Perevozka.Travel',
    iconSrc: 'facebook.svg',
    iconGradientSrc: 'facebook-gradient.svg',
  },
  {
    id: 2,
    title: 'perevozka.travel',
    href: 'https://www.instagram.com/perevozka.travel/',
    iconSrc: 'instagram.svg',
    iconGradientSrc: 'instagram-gradient.svg',
  },
  {
    id: 3,
    title: 'perevozka_travel',
    href: 'https://www.telegram.me/perevozka_travel',
    iconSrc: 'telegram.svg',
    iconGradientSrc: 'telegram-gradient.svg',
  },
  {
    id: 4,
    title: 'perevozka.travel@gmail.com',
    href: 'mailto:perevozka.travel@gmail.com',
    iconSrc: 'gmail.svg',
    iconGradientSrc: 'gmail-gradient.svg',
  },
]
