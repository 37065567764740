import Link from 'next/link'
import { useRouter } from 'next/router'

import { IAccentColor, IChildren } from '@interfaces/global.type'

import styles from './SideBarLinkItem.module.sass'

interface IProps {
  children: IChildren
  href: string
  color: IAccentColor
  onClick?: () => void
  [key: string]: any
}

const SideBarLinkItem = ({ href, children, onClick, color }: IProps) => {
  const router = useRouter()

  const defaultStyles = {
    yellow: styles.yellow,
    blue: styles.blue,
    blueYellow: styles.blueYellow,
  }

  const activeStyles = {
    yellow: styles.yellowActive,
    blue: styles.blueActive,
    blueYellow: styles.blueYellowActive,
  }

  return (
    <div
      onClick={onClick}
      className={
        router.pathname === href
          ? `${styles.container} ${activeStyles[color]}`
          : `${styles.container} ${defaultStyles[color]}`
      }
    >
      <Link href={href}>{children}</Link>
    </div>
  )
}

export default SideBarLinkItem
