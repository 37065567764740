import { IChildren } from '@interfaces/global.type'

import styles from './SmallHeader.module.sass'

interface IProps {
  children: IChildren
  uppercase?: boolean
  className?: string
  style?: React.CSSProperties
}

const SmallHeader = ({
  children,
  uppercase = false,
  className,
  style,
}: IProps) => {
  const classNameCustom = styles[className] || styles.container

  return (
    <p
      style={{
        ...style,
        textTransform: uppercase ? 'uppercase' : 'none',
      }}
      className={classNameCustom}
    >
      {children}
    </p>
  )
}

export default SmallHeader
