import Link from 'next/link'
import { ReactSVG } from 'react-svg'

import { IFooterTypes } from '@interfaces/global.type'

import FooterLinkItem from '../FooterLinkItem/FooterLinkItem'
import SmallHeader from '../SmallHeader/SmallHeader'

import styles from './Footer.module.sass'

import {
  contactsMock,
  socialsDeliveryMock,
  socialsTransportationMock,
} from '@mock/footerMock'

interface IProps {
  contentMock: IFooterTypes
}

const Footer = ({ contentMock }: IProps) => {
  switch (contentMock) {
  case 'delivery':
    return (
      <div className={styles.container}>
        <div className={styles.topRow}>
          <div className={styles.column}>
            <div className={styles.logoContainer}>
              <Link href="/">
                <ReactSVG src="/icons/logo-yellow.svg" />
              </Link>
            </div>

            <div className={`${styles.privacyLinksContainer}`}>
              <Link href="privacyPolicy">Політика конфіденційності</Link>
              <Link href="termsOfUse">Умови використання</Link>
              <Link href="complaintProcedure">Рекламаційний порядок</Link>
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.smallHeaderContainer}>
              <SmallHeader className="small">
                  Зв`язатись з нами
              </SmallHeader>
            </div>

            <div className={styles.listContainer}>
              {contactsMock.map(item => (
                <FooterLinkItem
                  key={item.id}
                  iconSrc={item.iconSrc}
                  href={item.href}>
                  {item.title}
                </FooterLinkItem>
              ))}
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.smallHeaderContainer}>
              <SmallHeader className="small">
                  Наші соцмережі (доставка)
              </SmallHeader>
            </div>

            <div className={styles.listContainer}>
              {socialsDeliveryMock
                .filter(item => item.id !== 4)
                .map(item => (
                  <FooterLinkItem
                    key={item.id}
                    iconSrc={item.iconSrc}
                    href={item.href}>
                    {item.title}
                  </FooterLinkItem>
                ))}
            </div>
          </div>
        </div>

        <div className={styles.bottomRow}>
          <div className={styles.column}>
            <p>©Perevozka 2023. Всі права захищені</p>
          </div>

          <div className={styles.column}>
            <FooterLinkItem
              inlineStyles={{ marginTop: '0' }}
              href="mailto:perevozka.delivery@gmail.com"
              iconSrc="gmail.svg">
                perevozka.delivery@gmail.com
            </FooterLinkItem>
          </div>

          <div className={styles.column}>
            <FooterLinkItem
              inlineStyles={{ marginTop: '0' }}
              href="https://t.me/perevozka_ua"
              iconSrc="telegram.svg">
                Офіційний Telegram-канал з новинами
            </FooterLinkItem>
          </div>
        </div>
      </div>
    )
  case 'transportation':
    return (
      <div className={styles.container}>
        <div className={styles.topRow}>
          <div className={styles.column}>
            <div className={styles.logoContainer}>
              <Link href="/">
                <ReactSVG src="/icons/logo-blue.svg" />
              </Link>
            </div>

            <div className={`${styles.privacyLinksContainer}`}>
              <Link href="privacyPolicy">Політика конфіденційності</Link>
              <Link href="termsOfUse">Умови використання</Link>
              <Link href="complaintProcedure">Рекламаційний порядок</Link>
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.smallHeaderContainer}>
              <SmallHeader className="small">
                  Зв`язатись з нами
              </SmallHeader>
            </div>

            <div className={styles.listContainer}>
              {contactsMock.map(item => (
                <FooterLinkItem
                  key={item.id}
                  iconSrc={item.iconSrc}
                  href={item.href}>
                  {item.title}
                </FooterLinkItem>
              ))}
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.smallHeaderContainer}>
              <SmallHeader className="small">
                  Наші соцмережі (перевезення)
              </SmallHeader>
            </div>

            <div className={styles.listContainer}>
              {socialsTransportationMock
                .filter(item => item.id !== 4)
                .map(item => (
                  <FooterLinkItem
                    key={item.id}
                    color="blue"
                    iconSrc={item.iconSrc}
                    href={item.href}>
                    {item.title}
                  </FooterLinkItem>
                ))}
            </div>
          </div>
        </div>

        <div className={styles.bottomRow}>
          <div className={styles.column}>
            <p>©Perevozka 2023. Всі права захищені</p>
          </div>

          <div className={styles.column}>
            <FooterLinkItem
              color="blue"
              inlineStyles={{ marginTop: '0' }}
              href="mailto:perevozka.travel@gmail.com"
              iconSrc="gmail.svg">
                perevozka.travel@gmail.com
            </FooterLinkItem>
          </div>

          <div className={styles.column}>
            <FooterLinkItem
              color="blue"
              inlineStyles={{ marginTop: '0' }}
              href="https://t.me/perevozka_ua"
              iconSrc="telegram.svg">
                Офіційний Telegram-канал з новинами
            </FooterLinkItem>
          </div>
        </div>
      </div>
    )
  default:
    return (
      <div className={styles.container}>
        <div className={styles.topRow}>
          <div className={styles.column}>
            <div className={styles.logoContainer}>
              <Link href="/">
                <ReactSVG src="/icons/logo-blue-yellow-with-text.svg" />
              </Link>
            </div>

            <div className={styles.listContainer}>
              {contactsMock.map(item => (
                <FooterLinkItem
                  key={item.id}
                  iconSrc={item.iconSrc}
                  href={item.href}>
                  {item.title}
                </FooterLinkItem>
              ))}
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.smallHeaderContainer}>
              <SmallHeader className="small">
                  Наші соцмережі (доставка)
              </SmallHeader>
            </div>

            <div className={styles.listContainer}>
              {socialsDeliveryMock.map(item => (
                <FooterLinkItem
                  key={item.id}
                  // iconSrc={item.iconSrc}
                  iconGradientSrc={item.iconGradientSrc}
                  href={item.href}
                  color="blueYellow">
                  {item.title}
                </FooterLinkItem>
              ))}
            </div>
          </div>

          <div className={styles.column}>
            <div className={styles.smallHeaderContainer}>
              <SmallHeader className="small">
                  Наші соцмережі (перевезення)
              </SmallHeader>
            </div>

            <div className={styles.listContainer}>
              {socialsTransportationMock.map(item => (
                <FooterLinkItem
                  key={item.id}
                  // iconSrc={item.iconSrc}
                  iconGradientSrc={item.iconGradientSrc}
                  href={item.href}
                  color="blueYellow">
                  {item.title}
                </FooterLinkItem>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.bottomRow}>
          <div className={styles.column}>
            <p>©Perevozka 2023. Всі права захищені</p>
          </div>

          <div className={styles.column}>
            <div className={styles.listContainer}>
              <FooterLinkItem
                inlineStyles={{ marginTop: '0' }}
                href="https://t.me/perevozka_ua"
                // iconSrc="telegram.svg"
                iconGradientSrc={'telegram-gradient.svg'}
                color="blueYellow">
                  Офіційний Telegram-канал з новинами
              </FooterLinkItem>
            </div>
          </div>

          <div className={`${styles.privacyLinksContainer} ${styles.column}`}>
            <Link href="privacyPolicy">Політика конфіденційності</Link>
            <Link href="termsOfUse">Умови використання</Link>
            <Link href="complaintProcedure">Рекламаційний порядок</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
