import { IAccentColor } from '@interfaces/global.type'

interface ISidebarDataMock {
  id: number
  name: string
  href: string
  color: IAccentColor
}

export const sidebarMainMock: ISidebarDataMock[] = [
  {
    id: 1,
    name: 'Про Нас',
    href: '/aboutUs',
    color: 'blueYellow',
  },
  {
    id: 2,
    name: 'Послуги',
    href: '/services',
    color: 'blueYellow',
  },
  {
    id: 3,
    name: 'Пасажирські перевезення',
    href: '/transportation',
    color: 'blue',
  },
  {
    id: 4,
    name: 'Доставка посилок',
    href: '/delivery',
    color: 'yellow',
  },
  {
    id: 5,
    name: 'Часті запитання',
    href: '/faq',
    color: 'blueYellow',
  },
  {
    id: 6,
    name: 'Новини',
    href: '/news',
    color: 'blueYellow',
  },
]

export const sidebarTransportationMock: ISidebarDataMock[] = [
  {
    id: 1,
    name: 'Головна',
    href: '/',
    color: 'blueYellow',
  },
  {
    id: 2,
    name: 'Послуги',
    href: '/services',
    color: 'blueYellow',
  },
  {
    id: 3,
    name: 'Пасажирські перевезення',
    href: '/transportation',
    color: 'blue',
  },
  {
    id: 4,
    name: 'Актуальні маршрути',
    href: '/allRoutes',
    color: 'blue',
  },
  {
    id: 5,
    name: 'Новини',
    href: '/newsTransportation',
    color: 'blue',
  },
  // {
  //   id: 6,
  //   name: 'Актуальні знижки',
  //   href: '/discountsTransportation',
  //   color: 'blue',
  // },
]

export const sidebarDeliveryMock: ISidebarDataMock[] = [
  {
    id: 1,
    name: 'Головна',
    href: '/',
    color: 'blueYellow',
  },
  {
    id: 2,
    name: 'Послуги',
    href: '/services',
    color: 'blueYellow',
  },
  {
    id: 3,
    name: 'Доставка посилок',
    href: '/delivery',
    color: 'yellow',
  },
  {
    id: 4,
    name: 'Новини',
    href: '/newsDelivery',
    color: 'yellow',
  },
  // {
  //   id: 4,
  //   name: 'Актуальні знижки',
  //   href: '/discountsDelivery',
  //   color: 'yellow',
  // },
]
